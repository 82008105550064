import { createContext, useContext, useEffect, useState } from "react";
const RegionContext = createContext();
const validFormatRegex = /^(lec|lck|lpl|lcs)?\.(pedropickems\.com|lolpickems\.com|localhost:3000)$/gm;
const isPedroPickemRegex = /^.*(pedropickems\.com)/gm;
const isInvalidPedroRegex = /^.+(pedropickems\.com)/gm;
const isInvalidLoLPickemRegex = /^.+(lolpickems\.com)/gm;
const isInvalidLocalDevRegex = /^.+(localhost:3000)/gm;
// TODO: MOVE TO CONFIG.JSON
const validRegions = ["lec", "lcs", "lpl", "lck"]

function RegionProvider({ children }) {
    const [region, setRegion] = useState();
    const [isPedroPickem, setIsPedroPickem] = useState(false);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        let host = window.location.host;
        let protocol = window.location.protocol;
        let parts = host.split(".");
        let inputtedRegion = "";

        const isValidPedroPickemTest = isPedroPickemRegex.test(host);
        const isPedroPickemTest = isInvalidPedroRegex.test(host);
        const isLolPickemTest = isInvalidLoLPickemRegex.test(host);
        const isLocalDevTest = isInvalidLocalDevRegex.test(host);

        setIsPedroPickem(isValidPedroPickemTest);

        if (isValidPedroPickemTest) {
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = '/pedro.ico';
        }

        if (validFormatRegex.test(host)) {
            inputtedRegion = parts[0];
            // Set the location to the new url
            if (!validRegions.includes(inputtedRegion)) {

                window.location = protocol + "//" + parts.join(".");
            } else {
                setRegion(inputtedRegion);
                setIsLoading(false);
            }
        } else if (isPedroPickemTest) {
            // parts.splice(-2);
            window.location = "https://pedropickems.com";
            setIsLoading(false);
        } else if (isLolPickemTest) {
            window.location = "https://lolpickems.com";
        } else if (isLocalDevTest) {
            window.location = "http://localhost:3000";
        } else {
            setIsLoading(false);
        }
    }, []);

    return (
        <RegionContext.Provider
            value={{
                isRegionLoaded: isLoading,
                setRegion,
                region,
                isPedroPickem
            }}>
            {children}
        </RegionContext.Provider>
    );
}

function useRegion() {
    const context = useContext(RegionContext);

    if (context === undefined) {
        throw new Error("useRegion must be used within an RegionProvider");
    }

    return context;
}

export { RegionProvider, useRegion };