import LoadingScreen from './modules/loading-screen/LoadingScreen';
import { useAuth } from './scripts/AuthManager';
import InDevelopmentScreen from './modules/in-development/InDevelopmentScreen';

function App() {
  const { connectionStatus: { status, message } } = useAuth();
  return (
    <>
      {status === "loading" &&
        <LoadingScreen />
      }
      {status === "fail" &&
        <div>
          <p>If you see the page, please contact the bot managers.</p>
          <p>{message}</p>
        </div>
      }
      {status === "active" &&
        <div className="App">
          <InDevelopmentScreen/>
        </div>
      }
    </>
  );
}

export default App;
