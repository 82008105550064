import { createContext, useContext, useEffect, useState } from "react";
import { createClient } from "@supabase/supabase-js";
import axios from "axios";
axios.defaults.withCredentials = true;
const supabase = createClient(
  "https://rpxfiuyztkoqtozpdpaz.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6InJweGZpdXl6dGtvcXRvenBkcGF6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE2OTY3ODYxNDYsImV4cCI6MjAxMjM2MjE0Nn0.Arz7uisOYpW18n6b-Z_iKjgEK07AXheSl_WItQ2Lcxg"
);
const initialSession = await supabase.auth.getSession();
const AuthContext = createContext();

function AuthProvider({ children }) {
  const [connectionStatus, setConnectionStatus] = useState({ status: "loading" });
  const [session, setSession] = useState(initialSession?.data.session);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let mounted = true;

    async function getSession() {
      const {
        data: { session },
        error
      } = await supabase.auth.getSession();

      // only update the react state if the component is still mounted
      if (mounted) {
        if (error) {
          setConnectionStatus({ status: "fail", message: "Error connecting to database. Error message: " + error.message });
          setIsLoading(false);
          return;
        }

        setSession(session);
        setConnectionStatus({ status: "active" });
        setIsLoading(false);
      }
    }

    getSession();

    return () => {
      mounted = false;
    };
  }, []);

  useEffect(() => {
    const {
      data: { subscription }
    } = supabase.auth.onAuthStateChange((event, session) => {
      if (session) {
        setSession(session);
      }

      if (event === 'SIGNED_OUT') {
        setSession(null);
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  useEffect(() => {
    async function upsertUser() {
      try {
        const currentUser = session.user.identities[0];
        const currentUserDiscord = currentUser.identity_data;
        await supabase.from("users").upsert({ name: currentUserDiscord.full_name, discordID: currentUserDiscord.provider_id, userID: currentUser.user_id }, { onConflict: "discordID" }).select();
      } catch (e) {
        console.log(e);
      }
    }
    if(session != null) {
      upsertUser();
    }
  }, [session])

  async function signInWithDiscord() {
    await supabase.auth.signInWithOAuth({
      provider: "discord",
    });
  }

  async function getMyPredictions() {
      if(isLoading) {
          return [];
      }
      if (!session) return [];
      const sessionUserDiscordID = session?.user?.identities[0].identity_data.provider_id;
      const { data, error } = await supabase.from("matches").select("*, predictions(discordID, voteAbbr)").gt('id', 30).eq("predictions.discordID", sessionUserDiscordID).order('date', { ascending: false }).neq("status", "IDLE");

      if (error) return [];

      return data;
  }

  async function getActivePredictions() {
    if(isLoading) {
      return [];
    }
    const { data, error } = await supabase.from("matches").select().eq("status", "ACTIVE");
    if (error) return [];

    return data;
  }

  async function getPreviousPredictions() {
    if(isLoading) {
      return [];
    }
    const { data, error } = await supabase.from("matches").select().eq("status", "CLOSED");
    if (error) return [];

    return data;
  }

  async function getLeaderboard() {
    if(isLoading) {
      return [];
    }
    const { data, error } = await supabase.from("users").select().order("correct_predictions", { ascending: false }).order("name", { ascending: true }).limit(100);

    if (error) return [];

    return data;
  }
  
  async function getCurrentUserInLeaderboard() {
    if(isLoading) {
      return [];
    }
    const username = session?.user?.identities[0].identity_data.full_name

    return await getPosInLeaderboard(username);
  }

  async function getPosInLeaderboard(username) {
    if(isLoading) {
      return [];
    }
    const { data, error } = await supabase.from("leaderboard").select().eq("name", username);

    if (error) return [];

    return data;
  }

  async function getGame(gameID) {
    if(isLoading) {
      return [];
    }
    if (!gameID) return;
    const sessionUserDiscordID = session?.user?.identities[0].identity_data.provider_id;
    const { data, error } = await supabase.from("matches").select("*, predictions(*)").eq("id", gameID).eq("predictions.discordID", sessionUserDiscordID).eq("predictions.matchID", gameID);

    if (error) return [];

    return data;
  }

  async function getResults(gameID, teamOneAbbr, teamTwoAbbr) {
    if(isLoading) {
      return [];
    }
    if (!gameID) return;
    const team1VotesRaw = await supabase.from("predictions").select("*", { count: "exact", head: true }).eq("matchID", gameID).eq("voteAbbr", teamOneAbbr);

    const team2VotesRaw = await supabase.from("predictions").select("*", { count: "exact", head: true }).eq("matchID", gameID).eq("voteAbbr", teamTwoAbbr);

    const team1Votes = team1VotesRaw.count;
    const team2Votes = team2VotesRaw.count;
    return { teamOneVotes: team1Votes, teamTwoVotes: team2Votes };
  }

  async function predictGame(gameID, teamAbbr) {
    if(isLoading) {
      return false;
    }
    const sessionUserDiscordID = session?.user?.identities[0].identity_data.provider_id;
    const { data, error } = await supabase.from("predictions").upsert({ discordID: sessionUserDiscordID, matchID: gameID, voteAbbr: teamAbbr, timestamp: new Date() }, { onConflict: "discordID, matchID" }).select();
    if (error || data == null || data.length === 0) return false;
    return true;
  }

  async function getActivePredictionsAndVotes() {
    if(isLoading) {
      return [];
    }
    const { data, error } = await supabase.rpc("getActivePredictionsAndVotes")

    if (error) return [];

    return data;
  }

  async function signOut() {
    await supabase.auth.signOut();
  }

    return (
        <AuthContext.Provider
            value={{
                connectionStatus,
                session,
                setSession,
                supabase,
                signInWithDiscord,
                getCurrentUserInLeaderboard,
                getPosInLeaderboard,
                getMyPredictions,
                getActivePredictions,
                getPreviousPredictions,
                getLeaderboard,
                predictGame,
                getGame,
                getResults,
                signOut,
                getActivePredictionsAndVotes
            }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth() {
    const context = useContext(AuthContext);

    if (context === undefined) {
        throw new Error("useAuth must be used within an AuthProvider");
    }

    return context;
}

export { AuthProvider, useAuth };
