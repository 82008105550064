function InDevelopmentScreen(props) {
    return (
        <div id="in-dev-screen">
            <h2 id="title">We are under development</h2>
            <div id="in-development-frame">
                <div className="region-select-object">
                    <div className="region-select-object-cover-layer" onClick={() => window.location = "https://discord.com/invite/caedrel"}>
                        <div className="region-select-photo">
                            <img
                                alt="Discord"
                                src={`/global/discord-logo.svg`}
                            />
                            <h2 id="title" style={{fontSize: "32px"}}>Caedrel's Discord</h2>
                        </div>
                    </div>
                </div>
                <div className="region-select-object">
                    <div className="region-select-object-cover-layer" onClick={() => window.location = "https://discord.gg/QJaRgYpZgq"}>
                        <div className="region-select-photo">
                            <img
                                alt="Discord"
                                src={`/global/discord-logo.svg`}
                            />
                            <h2 id="title" style={{fontSize: "32px"}}>Dev's Discord</h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InDevelopmentScreen;